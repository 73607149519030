import React from 'react';
import { Heading, DownloadCard } from '@yieldstreet/ui-kit';

import Anchor from 'components/utils/Anchor';
import { useToggleListVisibility } from 'hooks/useToggleListVisibility';
import { documents, resources } from './Resources.model';
import {
  CardWrapper,
  DocumentHolder,
  DocumentsSection,
  SubTitle,
  SButton,
  Wrapper,
  SSectionContainer,
} from './Resources.style';

export const Resources = () => {
  const { toggleDocsVisibility, visibleList, showAllListItems } = useToggleListVisibility(
    documents,
    9
  );

  return (
    <SSectionContainer>
      <Wrapper>
        <div>
          <Heading tagType={2} type={2}>
            Resources
          </Heading>
        </div>
        <DocumentsSection>
          <Anchor anchorId="resources" />
          <SubTitle type={4}>Key documents</SubTitle>
          <DocumentHolder>
            {resources.map(doc => (
              <CardWrapper key={doc.name} target="_blank" rel="noreferrer" href={doc.url}>
                <DownloadCard description={doc.name} />
              </CardWrapper>
            ))}
          </DocumentHolder>

          <SubTitle type={4}>Additional documents</SubTitle>
          <DocumentHolder>
            {visibleList.map(doc => (
              <CardWrapper key={doc.name} target="_blank" rel="noreferrer" href={doc.url}>
                <DownloadCard description={doc.name} />
              </CardWrapper>
            ))}
          </DocumentHolder>
          <SButton buttonType="secondary" onClick={toggleDocsVisibility}>
            {showAllListItems ? 'Hide documents' : 'Show more documents'}
          </SButton>
        </DocumentsSection>
      </Wrapper>
    </SSectionContainer>
  );
};
