import { useState } from 'react';

export const useToggleListVisibility = (list, itemsToShow) => {
  const [showAllListItems, setShowAllListItems] = useState(false);
  const listCopy = [...list];
  const visibleList = showAllListItems ? list : listCopy.splice(0, itemsToShow);

  const toggleDocsVisibility = () => setShowAllListItems(!showAllListItems);

  return {
    toggleDocsVisibility,
    visibleList,
    showAllListItems,
  };
};
