import { ReactNode } from 'react';

interface FundStatsProps {
  label: ReactNode;
  value: string;
  modalId: string;
  footNote?: string;
}

export const stats: FundStatsProps[] = [
  {
    label: 'Net annualized yield',
    value: '7.1%',
    modalId: 'net-annualized-yield-modal',
    footNote: 'as of 12/31/2024',
  },
  {
    label: 'Management fee',
    value: '1.0%',
    modalId: 'fees-modal',
    footNote: 'Adjusted annual expense ratio: 2.7%',
  },
  {
    label: 'Tax document',
    value: '1099-DIV',
    modalId: '',
  },
  {
    label: 'Minimum investment',
    value: '$10K',
    modalId: '',
  },
];
