import React from 'react';
import { TextBlock, SectionContainer, Paragraph } from '@yieldstreet/ui-kit';
import { useToggleListVisibility } from 'hooks/useToggleListVisibility';

import { holdingsData } from './Holdings.model';
import {
  AssetClassesWrapper,
  TableHeader,
  TableRow,
  NameWrapper,
  DescriptionWrapper,
  PercentageWrapper,
  SButton,
  MobileOnly,
} from './Holdings.style';

export const Holdings = () => {
  const { toggleDocsVisibility, visibleList, showAllListItems } = useToggleListVisibility(
    holdingsData,
    4
  );

  return (
    <AssetClassesWrapper>
      <SectionContainer>
        <TextBlock
          heading="A selection of holdings"
          align="center"
          mobileAlign="start"
          content={
            <>
              <Paragraph>
                The following are the top 10 holdings in the Fund, as of September 30, 2024.
              </Paragraph>
            </>
          }
        />
        <TableHeader>
          <NameWrapper>Industry</NameWrapper>
          <DescriptionWrapper>Description</DescriptionWrapper>
          <PercentageWrapper>%</PercentageWrapper>
        </TableHeader>
        {visibleList.map(item => (
          <TableRow key={item.id}>
            <NameWrapper>
              <MobileOnly>
                <Paragraph size="chart">Asset Class</Paragraph>
              </MobileOnly>
              {item.type}
            </NameWrapper>
            <DescriptionWrapper>{item.description}</DescriptionWrapper>
            <PercentageWrapper>
              <MobileOnly>
                <Paragraph size="chart">Allocation</Paragraph>
              </MobileOnly>
              {item.percentage}
            </PercentageWrapper>
          </TableRow>
        ))}
        <SButton buttonType="secondary" onClick={toggleDocsVisibility}>
          View {showAllListItems ? 'less' : 'all'}
        </SButton>
      </SectionContainer>
    </AssetClassesWrapper>
  );
};
