import React, { useState } from 'react';
import {
  ExternalLink,
  Label,
  NewModal,
  Paragraph,
  SectionContainer,
  TextBlock,
  useModalContext,
  Tab,
  TabList,
  Heading,
  MobileOnly,
  DesktopOnly,
  MarketingCinematicContainer,
} from '@yieldstreet/ui-kit';

import fundPerformanceDesktop from 'images/home/fund-performance-desktop.svg';
import fundGrowthDesktop from 'images/home/fund-growth-chart-desktop.svg';
import fundGrowthMobile from 'images/home/fund-growth-chart-mobile.svg';

import { modalId } from './FundPerformance.model';
import {
  FundPerformanceSection,
  BannerWrapper,
  PerformanceBanner,
  LegalTextWrapper,
  ModalWrapper,
  TabsWrapper,
  TabVisible,
  GrowthBanner,
} from './FundPerformance.style';

export const FundPerformance = () => {
  const { showModal } = useModalContext();
  const [active, setActive] = useState(0);
  const handleChange = (_event: React.SyntheticEvent, index: number) => setActive(index);

  return (
    <FundPerformanceSection>
      <TextBlock
        heading="Performance across market cycles"
        align="center"
        headingTagType={2}
        headingType={2}
      />
      <TabsWrapper>
        <TabList activeIndex={active} onChange={handleChange}>
          <Tab title="Returns" />
          <Tab title="Growth" />
        </TabList>
      </TabsWrapper>
      <MarketingCinematicContainer withBorder>
        <SectionContainer>
          <TabVisible isActive={active === 0}>
            <BannerWrapper>
              <Heading type={3} tagType={4}>
                Returns as of December 31, 2024
              </Heading>
              <PerformanceBanner src={fundPerformanceDesktop} />
            </BannerWrapper>
          </TabVisible>

          <TabVisible isActive={active === 1}>
            <BannerWrapper>
              <Heading type={3} tagType={4}>
                Growth of $100K
              </Heading>
              <MobileOnly>
                <GrowthBanner src={fundGrowthMobile} alt="" />
              </MobileOnly>
              <DesktopOnly>
                <GrowthBanner src={fundGrowthDesktop} alt="" />
              </DesktopOnly>
            </BannerWrapper>
          </TabVisible>

          <LegalTextWrapper>
            <Label>Source: Yieldstreet and Bloomberg. As of December 31, 2024.</Label>{' '}
            <ExternalLink onClick={() => showModal(modalId)}>
              View important disclosures
            </ExternalLink>
          </LegalTextWrapper>
        </SectionContainer>
        <NewModal modalId={modalId}>
          <ModalWrapper>
            <TextBlock
              heading="Historical performance chart; use of benchmarks"
              headingTagType={3}
              headingType={3}
              content={
                <>
                  <Paragraph>
                    Performance returns quoted herein represent past performance, which is not a
                    guarantee of future results. Returns for periods longer than one year are
                    presented as annualized returns. All investing involves risk, including the loss
                    of principal. Investment returns and principal value will fluctuate, so you may
                    have a gain or loss when shares are sold. The financial index returns referenced
                    above are provided for informational purposes only. The holdings and portfolio
                    characteristics of Yieldstreet Alternative Income Fund differ materially from
                    those of the index. The returns for the Yieldstreet Alternative Income Fund
                    represent the returns from the Fund's net asset value per share, assuming the
                    reinvestment of all distributions.
                  </Paragraph>
                  <Paragraph>
                    The Bloomberg Barclays US Aggregate Bond Index measures the investment grade, US
                    dollar denominated, fixed-rate taxable bond market. The index includes
                    Treasuries, government related and corporate securities, fixed-rate agency MBS,
                    ABS and CMBS.
                  </Paragraph>
                  <Paragraph>
                    The Bloomberg US Corporate High Yield measures the performance of U.S.
                    dollar-denominated, high-yield corporate bonds issued by companies whose country
                    of risk use official G-10 currencies, excluding those countries that are members
                    of the United Nations Eastern European Group. Qualifying securities must have a
                    below-investment-grade rating and maturities of one or more months.
                  </Paragraph>
                  <Paragraph>
                    Factors affecting portfolio performance that do not affect index performance may
                    include, but are not limited to, portfolio rebalancing, the timing of cash
                    flows, differences in asset classes invested in which the Fund invests and the
                    index represents, and investment duration. In addition, financial indices do not
                    reflect the impact of fees, applicable taxes or trading costs which reduce
                    returns. Unless otherwise noted, financial indices assume reinvestment of
                    dividends and interest. All indices are unmanaged. You cannot make a direct
                    investment in an index. The statistical data regarding such indices has not been
                    independently verified by Yieldstreet.
                  </Paragraph>
                  <Paragraph>
                    The Yieldstreet Alternative Income Fund's inception date is March 9, 2020.
                  </Paragraph>
                </>
              }
            />
          </ModalWrapper>
        </NewModal>
      </MarketingCinematicContainer>
    </FundPerformanceSection>
  );
};
