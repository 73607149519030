interface HoldingProps {
  id: string;
  type: string;
  description: string;
  percentage: string;
}

export const holdingsData: HoldingProps[] = [
  {
    id: '1',
    type: 'Transportation',
    description: 'Participation in the purchase and leasing of two commercial aircrafts.',
    percentage: '6.4%',
  },
  {
    id: '2',
    type: 'Commercial Real Estate',
    description:
      'A levered mortgage and mezzanine loan secured by 65 newly-built units in a condominium building located in North Bergen, NJ.',
    percentage: '5.9%',
  },
  {
    id: '3',
    type: 'Residential Real Estate',
    description: 'First mortgage loan secured by a multi-family property in Phoenix, AZ.',
    percentage: '4.6%',
  },
  {
    id: '4',
    type: 'Consumer',
    description: 'Consumer installment loans secured by 12K+ powersports vehicles.',
    percentage: '4.0%',
  },
  {
    id: '5',
    type: 'Residential Real Estate',
    description:
      'A junior mortgage and mezzanine loan primarily secured by a substantially completed multi-family development and parcel of land in Harrison, New Jersey.',
    percentage: '3.9%',
  },
  {
    id: '6',
    type: 'Residential Real Estate',
    description:
      'Subordinate loan secured by a 7-story luxury condo building located in the Lower East Side of New York City',
    percentage: '3.9%',
  },
  {
    id: '7',
    type: 'Consumer',
    description: 'Consumer installment loans secured by 12K+ powersports vehicles.',
    percentage: '3.8%',
  },
  {
    id: '8',
    type: 'Consumer',
    description:
      'SPC-sponsored private receivables securitization program serviced by LendingPoint.',
    percentage: '3.7%',
  },
  {
    id: '9',
    type: 'Residential Real Estate',
    description:
      'Note-on-note loan secured by a collateral assignment of a property in Edgewater, Miami.',
    percentage: '3.3%',
  },
  {
    id: '10',
    type: 'Legal Finance',
    description: 'Commitment to invest in Parabellum’s litigation finance fund.',
    percentage: '3.3%',
  },
];
