import styled from 'styled-components';
import { media, Button, ParagraphChartText, ParagraphRegularText } from '@yieldstreet/ui-kit';

export const AssetClassesWrapper = styled.section`
  ${media.desktop`
    padding: ${({ theme }) => theme.spacing['2xl']};
  `}
`;

export const TableHeader = styled.div`
  display: none;

  ${media.desktop`
    display: flex;
    padding: ${({ theme }) => theme.spacing.m};
  `}
`;

export const MobileOnly = styled.div`
  ${media.desktop`
    display: none;
  `}
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  text-align: left;
  padding: ${({ theme }) => theme.spacing.m};
  background-color: ${({ theme }) => theme.colors.background_light};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  border-radius: 8px;

  ${media.desktop`
    flex-direction: row;
    flex-wrap: nowrap;
  `}
`;

export const NameWrapper = styled.div`
  order: 1;
  width: 70%;

  ${media.desktop`
    order: unset;
    width: 25%;
  `}
`;
export const DescriptionWrapper = styled.div`
  ${ParagraphChartText};
  order: 3;
  padding-top: 8px;

  ${media.desktop`
    ${ParagraphRegularText};
    order: 2;
    padding-top: 0;
    width: 70%;
  `}
`;
export const PercentageWrapper = styled.div`
  order: 2;
  width: 30%;

  ${media.desktop`
    order: 3;
    width: 5%;
    text-align: right;
  `}
`;

export const SButton = styled(Button)`
  display: block;
  margin: ${({ theme }) => theme.spacing.m} auto;
`;
